<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { useSiteStore } from '~/stores/site.store';

const { $t } = useNuxtApp();
const auth = useAuthStore();
const site = useSiteStore();
const { $toastService } = useServices();

const idVerificationStatus: globalThis.Ref<string> = ref('');
const welcomeOffer: globalThis.Ref<string> = ref('');
const securityQuestion: globalThis.Ref<string> = ref('');
const securityAnswer: globalThis.Ref<string> = ref('');

onMounted(() => {
  //make call and set all vals
  idVerificationStatus.value = 'Successful';
  // welcomeOffer.value = auth.getWelcomeOffer;
  // securityQuestion.value = 'Childhood pet';
  // securityAnswer.value = 'Cuddles (RIP)';
});

function depositNow() {
  site.deactivateModal();
  auth.toggleAccountModal(true);
  auth.setAccountModalPage('deposit');
}

function depositLater() {
  site.deactivateModal();
}

onUnmounted(() => {
  if (site.getFeatureFlags.get('website.toastmessages')) {
    $toastService.toastService.fetchToasts();
  }
});
</script>

<template>
  <div class="flex flex-col flex-wrap justify-center align-content-start mx-3">
    <div class="flex justify-center mt-3 w-full">
      <CheckCircleIcon class="text-primary-blue-700 w-16 h-16" />
    </div>
    <p class="font-bold text-base mb-3">{{ $t('success-complete-profile') }}</p>
    <p class="text-base mb-3">
      <strong>{{ $t('id-verification') }}:&nbsp;</strong>
      <span class="text-primary-blue-500">
        {{ $t(idVerificationStatus) }}
      </span>
    </p>
    <div v-if="auth.getWelcomeOffer">
      <p class="font-bold my-0 text-base">
        {{ $t('jpc-welcome-offer-chosen') }}:
      </p>
      <p class="mt-1 mb-1 text-base">{{ $t('jpc-welcome-offer-header') }}</p>
      <p class="mt-0 line-height-2 text-base">
        {{ $t('jpc-welcome-offer-body') }}
      </p>
    </div>
    <!-- TODO: Feature flag on security questions & Welcome Offer -->
    <!-- <p v-if="auth.getWelcomeOffer">
      <strong>{{ $t('welcome-offer-chosen') }}:</strong>
      {{ $t(welcomeOffer) || $t('not-selected') }}
    </p>
    <p v-if="false">
      <strong>{{ $t('security-question') }}:</strong>
      {{ $t(securityQuestion) }}
    </p>
    <p v-if="false">
      <strong>{{ $t('security-answer') }}:</strong>
      {{ $t(securityAnswer) }}
    </p>-->
  </div>
  <div class="w-full dark:bg-dark-900 bg-light-200 flex justify-center">
    <Button class="w-full m-3 justify-center" @click="depositNow()">
      {{ $t('deposit-now') }}
    </Button>
    <Button
      class="w-full m-3 justify-center"
      type="secondary"
      @click="depositLater()"
    >
      {{ $t('deposit-later') }}
    </Button>
  </div>
</template>

<style scoped lang="scss"></style>
